import PropTypes from 'prop-types'
import React, {useState} from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert";

const CardPricing = props => {
  const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
  const [createFailed, setCreateFailed] = useState(false)

  const handleLoanApplication = async () => {
    let toPost = {
      email: props?.email,
      isCore: false,
      totalInstallments: props?.pricing?.totalInstallments,
      profit: props?.pricing?.profit,
      amount: props?.pricing?.amount,
      loanName: props?.pricing?.loanName,
    }
    console.log({toPost})
    try {
      const response = await fetch("https://demobackend.mudiaga.com.ng/api/loans/new", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toPost),
      });

      console.log("Here")
      const result = await response.json();
      setCreatedSuccessfully(true)
      console.log("Result:", result);
    } catch (error) {
      console.error("Error:", error);
      setCreateFailed(true)
    }
  }

  return (
    <React.Fragment>
      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">
            <div className="d-flex align-items-start">
              <div body className="flex-1 me-3">
                <h5>{props.pricing?.profit}% per year</h5>
                {/*<p className="text-muted">{props.pricing.description}</p>*/}
              </div>
              <div className="ms-auto">
                <i
                  className={"bx bx-walk h1 text-primary"}
                />
              </div>
            </div>
            <div className="py-4 mt-4 text-center bg-soft-light">
              <h1 className="m-0">{props?.pricing?.loanName}</h1>
            </div>
            <div className="plan-features p-4 text-muted mt-2">
              {/*{props.pricing.features.map((feature, key) => (*/}
                <p>
                  <i className="mdi mdi-check-bold text-primary me-4" />
                  Minimum: &nbsp;&nbsp;&nbsp;&nbsp;${props?.pricing?.amount}
                </p>
              <p>
                <i className="mdi mdi-check-bold text-primary me-4" />
                Maximum: &nbsp;&nbsp;&nbsp;&nbsp;${Number(props?.pricing?.amount) * 2}
              </p>
              <p>
                <i className="mdi mdi-check-bold text-primary me-4" />
                Duration: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1 Year
              </p>
              <p>
                <i className="mdi mdi-check-bold text-primary me-4" />
                Installments: &nbsp;&nbsp;3
              </p>
              {/*))}*/}
            </div>
            <div className="text-center">
              <Link
                to={props.pricing.link}
                className="btn btn-primary btn-lg waves-effect waves-light"
                onClick={handleLoanApplication}
              >
                Apply
              </Link>
            </div>
          </CardBody>
        </Card>
      </Col>
      {createdSuccessfully ? (
          <SweetAlert
              title="Loan Application Successful"
              success
              // showCancel
              confirmBtnBsStyle="success"
              // cancelBtnBsStyle="danger"
              onConfirm={() => {
                setCreatedSuccessfully(false)
                window.location.reload();
              }}
              // onCancel={() => {
              //     setSuccessMsg(false)
              // }}
          >
            You will be contacted by one of our agents soon!
          </SweetAlert>
      ) : null}
      {createFailed ? (
          <SweetAlert
              title="Error in loan application"
              warning
              // showCancel
              confirmButtonText="Ok"
              confirmBtnBsStyle="success"
              // cancelBtnBsStyle="danger"
              onConfirm={() => {
                setCreateFailed(false)
                // setsuccess_dlg(true)
                // setdynamic_title("Deleted")
                // setdynamic_description("Your file has been deleted.")
              }}
              // onCancel={() => setconfirm_alert(false)}
          >
            Please try again later! Also check if you have an uncleared loan!
          </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

CardPricing.propTypes = {
  pricing: PropTypes.object
}

export default CardPricing
