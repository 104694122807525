import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import VerifyingEmail from "../pages/Authentication/VerifyingEmail";
import UserProfile from "../pages/Authentication/user-profile";
import Beneficiaries from "../pages/Dashboard/Beneficiaries";
import WireTransfer from "../pages/Dashboard/wireTransfer";
import InterbankTransfer from "../pages/Dashboard/interbankTransfer";
import LoanAdmin from "../pages/Dashboard/LoanAdmin";
import LoanApply from "../pages/Dashboard/LoanApply";
import LoanHistory from "../pages/Dashboard/LoanHistory";
import FDRAdmin from "../pages/Dashboard/FDRAdmin";
import FDRApply from "../pages/Dashboard/FDRApply";
import FDRHistory from "../pages/Dashboard/FDRHistory";
import TransactionHistory from "../pages/Dashboard/TransactionHistory";
import ManageUsers from "../pages/Dashboard/ManageUsers";
import ManageFDR from "../pages/Dashboard/ManageFDR";
import ManageLoans from "../pages/Dashboard/ManageLoans";

const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/profile", component: UserProfile },
  { path: "/beneficiaries", component: Beneficiaries },
  { path: "/wire-transfer", component: WireTransfer },
  { path: "/interbank-transfer", component: InterbankTransfer },
  { path: "/manage-loan", component: LoanAdmin },
  { path: "/loan-application", component: LoanApply },
  { path: "/loans", component: LoanHistory },
  { path: "/manage-fdr", component: FDRAdmin },
  { path: "/fdr-application", component: FDRApply },
  { path: "/fdr", component: FDRHistory },
  { path: "/transactions", component: TransactionHistory },
  { path: "/manage-users", component: ManageUsers },
  { path: "/manage-fdradmin", component: ManageFDR },
  { path: "/manage-loanadmin", component: ManageLoans },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/email/verify/:code", component: VerifyingEmail },

]

export { userRoutes, authRoutes }
