import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile , resetProfileFlag} from "../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert";

const InterbankTransfer = props => {
  const [email, setEmail] = useState("")
  const [accountName, setAccountName] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [accountType, setAccountType] = useState("")
  const [country, setCountry] = useState("")
  const [swiftCode, setSwiftCode] = useState("")
  const [routingNumber, setRoutingNumber] = useState("")
  const [bankTo, setBankTo] = useState("")
  const [amount, setAmount] = useState("")
  const [note, setNode] = useState("")
  const [currency, setCurrency] = useState("$")
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("")
  const [allbeneficiaries, setAllbeneficiaries] = useState([])
  const [sentSuccessfully, setSentSuccessfully] = useState(false)
  const [sendingFailed, setSendingFailed] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setEmail(obj?.email)
      setCurrency(obj?.currency)
      setTimeout(() => {
        props.resetProfileFlag();
      }, 3000);
    }
  }, [props.success])

  async function handleValidSubmit(event, values) {
    console.log(values)
    let toPost = {
      email: email,
      ...values
    }
    try {
      const response = await fetch("https://demobackend.mudiaga.com.ng/api/interbank-transfer/new", {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toPost),
      });

      const result = await response.json();
      setSentSuccessfully(true)
      console.log("Result:", result);
    } catch (error) {
      console.error("Error:", error);
      setSendingFailed(true)
    }
  }

  useEffect(() => {
    fetch(`https://demobackend.mudiaga.com.ng/api/beneficiary/all/1/1000`)
        .then(res => res.json())
        .then(res => {
          console.log({res})
          let bens = res?.data?.docs?.filter(el => el?.status !== "deleted")
          setAllbeneficiaries(bens)
        })
        .catch(err => {
          console.log({err})
        })
  },[])

  const handleSelectBeneficiary = (val) => {
    let ben = allbeneficiaries?.filter(el => el?.accountNumber === val)
    console.log({ben})
    setAccountName(ben[0]?.accountName)
    setAccountNumber(val)
    setAccountType(ben[0]?.accountType)
  }

  return (
    <React.Fragment>
      <div className="page-content">

          {/* Render Breadcrumb */}
          <Breadcrumb title="Bein Trust Bank" breadcrumbItem="Inter Bank Transfer" />

          <h3 className="card-title mb-4">New Wire Transfer</h3>

          <Card>
            <CardBody>
              <AvForm
                className="form-horizontal"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField type="select" name="beneficiary" label="Beneficiary"
                               value={selectedBeneficiary} onChange={(e) => handleSelectBeneficiary(e?.target?.value)} required>
                        <option>Select a Beneficiary</option>
                        {
                          allbeneficiaries?.map((item, index) =>
                              <option value={item?.accountNumber}>{item?.accountName}</option>
                          )
                        }
                      </AvField>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="accountName"
                          label="Account Name"
                          value={accountName}
                          className="form-control"
                          placeholder="Enter the beneficiary account name"
                          type="text"
                          disabled
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="accountNumber"
                          label="Account Number"
                          value={accountNumber}
                          className="form-control"
                          placeholder="Enter beneficiary account number"
                          type="text"
                          disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <AvField
                          name="accountType"
                          label="Account Type"
                          value={accountType}
                          className="form-control"
                          placeholder="Enter the account type"
                          type="text"
                          disabled
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="currency"
                          label="Currency"
                          value={currency}
                          className="form-control"
                          placeholder=""
                          type="text"
                          disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 mb-3">
                    <div className="form-group">
                      <AvField
                          name="amount"
                          label="Amount"
                          value={amount}
                          className="form-control"
                          placeholder="Enter transaction amount"
                          type="text"
                          required
                      />
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <AvField
                          name="note"
                          label="Note"
                          value={note}
                          className="form-control"
                          placeholder="Enter an optional note"
                          type="textarea"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Initiate Transfer
                  </Button>
                </div>
              </AvForm>
            </CardBody>
          </Card>

        {sentSuccessfully ? (
            <SweetAlert
                title="Inter Bank Transfer Initiated successfully"
                success
                // showCancel
                confirmBtnBsStyle="success"
                // cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setSentSuccessfully(false)
                  window.location.reload();
                }}
                // onCancel={() => {
                //     setSuccessMsg(false)
                // }}
            >
              You will be notified via email of the progress!
            </SweetAlert>
        ) : null}
        {sendingFailed ? (
            <SweetAlert
                title="Error in Initiating Inter Bank Transfer"
                warning
                // showCancel
                confirmButtonText="Ok"
                confirmBtnBsStyle="success"
                // cancelBtnBsStyle="danger"
                onConfirm={() => {
                  setSendingFailed(false)
                  // setsuccess_dlg(true)
                  // setdynamic_title("Deleted")
                  // setdynamic_description("Your file has been deleted.")
                }}
                // onCancel={() => setconfirm_alert(false)}
            >
              Please try again later!
            </SweetAlert>
        ) : null}
      </div>
    </React.Fragment>
  )
}

InterbankTransfer.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile , resetProfileFlag})(InterbankTransfer)
)
