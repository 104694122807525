import React, {useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Table} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";

const FDRHistory = () => {
    const [myLoans, setMyLoans] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})

    const columns = [
        {
            label: "Loan Name",
            field: "loanName",
            sort: "asc",
            width: 150,
        },
        {
            label: "Minimum Amount",
            field: "amount",
            sort: "asc",
            width: 270,
        },
        {
            label: "Profit (%)",
            field: "profit",
            sort: "asc",
            width: 200,
        },
        {
            label: "Installments Paid",
            field: "installmentsPaid",
            sort: "asc",
            width: 100,
        },
        {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 100,
        },
    ]

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)


    useEffect(() => {
        fetch(`https://demobackend.mudiaga.com.ng/api/FDR/user/${JSON.parse(localStorage.getItem("authUser"))?.email}`)
            .then(response => response.json())
            .then(response => {
                let res = response
                res?.data?.forEach((item, index) => {
                    item.id = (
                        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.id}</div>
                    );
                    if (item?.status === "pending"){
                        item.status = (
                            <div style={{backgroundColor: "#ffa60a", fontWeight: "800", textAlign: "center", color: "white"}}>Pending</div>
                        )
                    }
                    else if (item?.status === "active"){
                        item.status = (
                            <div style={{backgroundColor: "#f6666c", fontWeight: "800", textAlign: "center", color: "white"}}>Active</div>
                        )
                    }
                    else if (item?.status === "paid"){
                        item.status = (
                            <div style={{backgroundColor: "#6fad44", fontWeight: "800", textAlign: "center", color: "white"}}>Active</div>
                        )
                    }
                    console.log("Status: ", item?.status)
                })
                console.log({res})
                let myDocs = res?.data
                myDocs = myDocs?.filter(el => el?.status !== "deleted")
                setMyLoans(myDocs)
                setData({
                    columns,
                    rows: myDocs
                })
            })
            .catch(error => {
                console.log({error})
            })
    },[])


    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Bein Trust Bank" breadcrumbItem="My Federal Reserve" />

                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                {/*<MDBDataTable responsive bordered striped data={data} />*/}
                                <div className="table-responsive">
                                    <Table className="table table-responsive  table-hover mb-0">
                                        <thead className="table-light">
                                        <tr>
                                            <th>#</th>
                                            <th>FDR Name</th>
                                            <th>Minimum Amount</th>
                                            <th>Maximum Amount</th>
                                            <th>Date Applied</th>
                                            <th>Profit (%)</th>
                                            <th>Duration</th>
                                            <th>Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            myLoans?.map((item, index) => {
                                                return <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.FDRName}</td>
                                                    <td>{item?.minimum}</td>
                                                    <td>{item?.maximum}</td>
                                                    <td>{new Date(item?.dueDate).toDateString()}</td>
                                                    <td>{item?.profit}</td>
                                                    <td>{item?.lockedInPeriod}</td>
                                                    <td>{item?.status}</td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

        </React.Fragment>
    )
}

export default FDRHistory
