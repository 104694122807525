import PropTypes from "prop-types"
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText, Form, FormFeedback, Modal, ModalHeader, ModalBody, ModalFooter, Button
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { registerUser, apiError, registerUserFailed } from "../../store/actions"

// Redux
import { connect } from "react-redux"
import { Link } from "react-router-dom"

// import images
import logo from "../../assets/images/logo-sm-dark.png"
import countryList from "react-select-country-list";
import Select from "react-select";
import {CheckCircle, Visibility} from "@mui/icons-material";
import MultiStep from "react-multistep";
import axios from "axios";
import Logo from "../../assets/images/logo-dark.png";

const Register = props => {

  const [firstName, setFirstName] = useState("")
  const [values, setValues] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [ssn, setSSN] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [personalFormValidation, setPersonalFormValidation] = useState({});
  const [currentStep, setCurrentStep] = useState(0)
  const [nationality, setNationality] = useState('')
  const [country, setCountry] = useState('')
  const [modal, setModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const passwordVisibility = useRef(false)

  const toggle = () => setModal(!modal);
  const toggleError = () => setErrorModal(!errorModal);



  // const options = useMemo(() => countryList().getData(), [])

  // const nationalityHandler = value => {
  //   setNationality(value)
  // }
  //
  // const countryHandler = value => {
  //   setNationality(value)
  // }

  const validatePersonalInformation = () => {
    let validation = {}
    if (firstName?.length < 2 ){
      validation.firstName = false
    }

    if (lastName === ""){
      validation.lastName = false;
    }

    setPersonalFormValidation(validation)

    let result = true

    console.log({validation})

    for (let values in validation){
      result = result && values
    }

    return {result, validation};

  }

  const multistepRef = useRef();

  const StepOne = props => {

    return (<div className="p-2">
      <Form
          className="form-horizontal"
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6 com-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="firstName">First Name</Label>
                    <Input
                        type="firstName"
                        name="firstName"
                        id="firstName"
                        placeholder="Enter given name"
                        defaultValue={values?.firstName}
                    />
                    {/*{firstName < 2 && <p>Mudiiiii</p>}*/}
                    {/*<FormFeedback>*/}
                    {/*  First Name is compulsory.*/}
                    {/*</FormFeedback>*/}
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="lastName">Last Name</Label>
                    <Input
                        type="text"
                        name="latName"
                        id="lastName"
                        placeholder="Enter your family name"
                        defaultValue={values?.lastName}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        defaultValue={values?.email}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label htmlFor="nationality">Nationality</Label>
                    {/*<Select id="nationality" options={options} value={nationality} onChange={nationalityHandler} />*/}
                    <Input
                        type="text"
                        name="nationality"
                        id="nationality"
                        placeholder="Enter your nationality"
                        defaultValue={values?.country}
                    />
                  </FormGroup>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="ssn">Social Security or Tax ID Number</Label>
                    <Input
                        type="ssn"
                        name="ssn"
                        id="ssn"
                        placeholder="Enter social security or tax ID number"
                        defaultValue={values?.socialSecurityNumber}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="phoneNumber">Phone Number (with country code)</Label>
                    <Input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter your phone number"
                        defaultValue={values?.phoneNumber}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <InputGroup>
                      <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          placeholder="Enter the password for your online banking account"
                          defaultValue={values?.password}
                      />
                      {/*<InputGroupText onClick={() => {passwordVisibility.current = true}}>*/}
                      {/*  <Visibility />*/}
                      {/*</InputGroupText>*/}
                    </InputGroup>

                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <InputGroup>
                      <Input
                          type={showConfirmPassword? "text" : "password"}
                          name="confirmPassword"
                          id="confirmPassword"
                          placeholder="Re-type your password"
                          defaultValue={values?.confirmPassword}
                      />
                      {/*<InputGroupText onClick={() => {setShowConfirmPassword(prevState => !prevState)}}>*/}
                      {/*  <Visibility />*/}
                      {/*</InputGroupText>*/}
                    </InputGroup>

                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>

      </Form>

    </div>)
  }

  const StepTwo = props => {

    return (<div className="p-2">
      <Form
          className="form-horizontal"
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label htmlFor="country">Country of residence</Label>
                    {/*<Select id="country" options={options} value={country} onChange={countryHandler} />*/}
                    <Input
                        type="text"
                        name="country"
                        id="country"
                        placeholder="Enter your country of residence"
                        defaultValue={values?.countryOfResidence}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="lastName">City</Label>
                    <Input
                        type="text"
                        name="city"
                        id="city"
                        placeholder="Enter your city of residence"
                        defaultValue={values?.city}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="firstName">State</Label>
                    <Input
                        type="text"
                        name="state"
                        id="state"
                        placeholder="Enter your state of residence"
                        defaultValue={values?.state}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="phoneNumber">Zip</Label>
                    <Input
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Enter your zip code"
                        defaultValue={values?.zip}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

          </div>
          <div className="mb-3">
            <FormGroup>
              <Label for="lastName">Address</Label>
              <Input
                  type="textarea"
                  name="address"
                  id="address"
                  placeholder="Enter your residential address"
                  defaultValue={values?.address}
              />
            </FormGroup>
          </div>
        </div>
      </Form>

    </div>)
  }

  const StepThree = props => {

    return (<div className="p-2">
      <Form
          className="form-horizontal"
      >
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label htmlFor="accountType">Account Type</Label>
                    <Input
                        id="accountType"
                        name="accountType"
                        type="select"
                        defaultValue={values?.accountType}
                    >
                      <option>
                        Checking
                      </option>
                      <option>
                        Savings
                      </option>
                      <option>
                        Fixed Deposit
                      </option>
                      <option>
                        Premium Finance
                      </option>
                    </Input>
                  </FormGroup>

                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label htmlFor="currency">Currency</Label>
                    <Input
                        id="currency"
                        name="currency"
                        type="select"
                        defaultValue={values?.currency}
                    >
                      <option value="$">
                        USD
                      </option>
                      <option value="&pounds;">
                        GBP
                      </option>
                      <option value="&euros;">
                        EUR
                      </option>
                      <option value="CAD">
                        CAD
                      </option>
                      <option value="AUD">
                        AUD
                      </option>
                    </Input>
                  </FormGroup>

                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="dmPin">Domestic Transfer Pin</Label>
                    <Input
                        type="text"
                        name="dmPin"
                        id="dmPin"
                        placeholder="Enter your domestic transfer pin"
                        defaultValue={values?.DomesticTransferPin}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="mb-3">
                  <FormGroup>
                    <Label for="fmPin">Foreign Transfer Pin</Label>
                    <Input
                        type="text"
                        name="fmPin"
                        id="fmPin"
                        placeholder="Enter your foreign transfer pin"
                        defaultValue={values?.ForeignTransferPin}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-3">
            <FormGroup>
              <Label for="description">Account Description</Label>
              <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder="Enter a brief description of your account"
                  defaultValue={values?.description}
              />
            </FormGroup>
          </div>
        </div>
      </Form>

    </div>)
  }

  const onNextClick = async (e) => {
    e.preventDefault()

    let isValid = validatePersonalInformation()
    console.log({isValid})

    if (currentStep == 0) {
      // setModal(true);

      let fn = document?.getElementById('firstName').value
      let ln = document?.getElementById('lastName').value
      let em = document?.getElementById('email').value
      let nn = document?.getElementById('nationality').value
      let ssn = document?.getElementById('ssn').value
      let pn = document?.getElementById('phoneNumber').value
      let pw = document?.getElementById('password').value
      let cpw = document?.getElementById('confirmPassword').value

      let k = {
        ...values, ...{
          firstName: fn,
          lastName: ln,
          email: em,
          country: nn,
          socialSecurityNumber: ssn,
          phoneNumber: pn,
          password: pw,
          confirmPassword: cpw
        }
      }
      setValues(k);

      console.log({k})
    } else if (currentStep === 1) {
      let ct = document.getElementById('country').value
      let st = document.getElementById('state').value
      let cty = document.getElementById('city').value
      let zp = document.getElementById('zip').value
      let adr = document.getElementById('address').value

      let k = {
        ...values, ...{
          countryOfResidence: ct,
          state: st,
          city: cty,
          zip: zp,
          address: adr
        }
      }

      setValues(k)

      console.log({k})
    } else {
      let at = document.getElementById('accountType').value
      let cr = document.getElementById('currency').value
      let dmPin = document.getElementById('dmPin').value
      let fmPin = document.getElementById('fmPin').value
      let dc = document.getElementById('description').value

      let k = {
        ...values, ...{
          accountType: at,
          currency: cr,
          DomesticTransferPin: dmPin,
          ForeignTransferPin: fmPin,
          description: dc,
        }
      }

      setValues(k)

      try {
        // console.log("K to Post: ", {k})
        const response = await fetch("https://demobackend.mudiaga.com.ng/api/register", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(k),
        });

        const result = await response.json();
        console.log("Success:", result);

        const response2 = await fetch("https://demobackend.mudiaga.com.ng/api/accounts/all/1/1000")
        let allBanks = await response2.json();
        allBanks = allBanks?.data?.docs;

        const banksLength = allBanks?.length;
        const baseAccountNumber = 519786457841326
        let newAccNo =`0${baseAccountNumber + banksLength}`

        k = {
          ...k, accountNumber: newAccNo,
          accountName: k?.firstName + " " + k?.lastName
        }

        console.log({allBanks})
        console.log({newAccNo})
        console.log({k})

        const response3 = await fetch("https://demobackend.mudiaga.com.ng/api/accounts/new", {
          method: "POST", // or 'PUT'
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(k),
        });

        const bankAccount = await response3.json();
        console.log("Successfully created bank account: ", bankAccount)

        setModal(true)
      } catch (error) {
        console.error("Error:", error);
        setErrorModal(true)
      }

      //   axios.post("https://demobackend.mudiaga.com.ng/register",JSON.stringify(k),{
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   })
      //       .then(res => console.log({res}))
      //       .catch(err => console.log({err}))
      //
      //   console.log({k})
    }


    // alert(document?.getElementById('firstName').value)

    if (currentStep < 2) {
      setCurrentStep(prevState => prevState + 1)
    }
  }

  const onPreviousClick = (e) => {
    e.preventDefault()

    if (currentStep == 0){
      let fn = document?.getElementById('firstName').value
      let ln = document?.getElementById('lastName').value
      let em = document?.getElementById('email').value
      let nn = document?.getElementById('nationality').value
      let ssn = document?.getElementById('ssn').value
      let pn = document?.getElementById('phoneNumber').value
      let pw = document?.getElementById('password').value
      let cpw = document?.getElementById('confirmPassword').value

      let k ={...values, ...{
          firstName: fn,
          lastName: ln,
          email: em,
          country: nn,
          socialSecurityNumber: ssn,
          phoneNumber: pn,
          password: pw,
          confirmPassword: cpw
        }}
      setValues(k);

      console.log({k})
    }
    else if (currentStep === 1){
      let ct  = document.getElementById('country').value
      let st  = document.getElementById('state').value
      let cty  = document.getElementById('city').value
      let zp  = document.getElementById('zip').value
      let adr  = document.getElementById('address').value

      let k = {
        ...values, ...{
          countryOfResidence: ct,
          state: st,
          city: cty,
          zip: zp,
          address: adr
        }
      }

      setValues(k)

      console.log({k})
    }
    else{
      let at  = document.getElementById('accountType').value
      let cr  = document.getElementById('currency').value
      let dmPin  = document.getElementById('dmPin').value
      let fmPin  = document.getElementById('fmPin').value
      let dc  = document.getElementById('description').value

      let k = {
        ...values, ...{
          accountType: at,
          currency: cr,
          DomesticTransferPin: dmPin,
          ForeignTransferPin: fmPin,
          description: dc,
        }
      }

      setValues(k)

      console.log({k})
    }

    if (currentStep > 0){
      setCurrentStep(prevState => prevState - 1)
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col className="text-center">
              <img src={Logo} style={{width: 150, height: 150}} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={10} lg={10} xl={10}>
              <Card className="overflow-hidden">
                <div className="card-body pt-5">
                  <div className="row mb-4">
                    <div className="col register-step-header" style={{ fontSize: 18, fontWeight: '800', color: 'black'}}>
                      1. Personal Information
                      {<CheckCircle style={{color: '#33c3f0', marginLeft: 10}} />}
                    </div>
                    <div className="col" style={{fontSize: 18, fontWeight: '800', color: 'black'}}>
                      2. Residential Information
                      {currentStep >= 1 && <CheckCircle style={{color: '#33c3f0', marginLeft: 10}} />}
                    </div>
                    <div className="col" style={{fontSize: 18, fontWeight: '800', color: 'black'}}>
                      3. Account Information
                      {currentStep >= 2 && <CheckCircle style={{color: '#33c3f0', marginLeft: 10}} />}
                    </div>
                  </div>
                  {
                    currentStep ===0 && <StepOne />
                  }
                  {
                      currentStep ===1 && <StepTwo />
                  }
                  {
                      currentStep ===2 && <StepThree />
                  }
                  <Modal isOpen={modal} toggle={toggle} centered={true}>
                    <ModalHeader toggle={toggle}>Bein Trust Bank</ModalHeader>
                    <ModalBody>
                      User signup successful. A verification email has been sent to your email.
                      Please verify your account to continue.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={() => window.location.href = "/login"}>
                        Ok
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <Modal isOpen={errorModal} toggle={toggleError} centered={true}>
                    <ModalHeader toggle={toggleError}>Bein Trust Bank</ModalHeader>
                    <ModalBody>
                      Error in signing up user. Please try again.
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={() => setErrorModal(false)}>
                        Ok
                      </Button>{' '}
                    </ModalFooter>
                  </Modal>

                  <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                      <div className="row">
                        <div className="col-sm-6">
                          <button className="btn"
                                  style={{backgroundColor: currentStep > 0? '#3b5de7': '#e0e0e0', width: "100%",
                                    color: currentStep > 0? "white": "black", height: 50, borderColor: currentStep > 0? '#3b5de7': "#e0e0e0",
                                    marginRight: 0, marginBottom: 10  }}
                          onClick={onPreviousClick}>
                            Previous
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button className="btn"
                                  style={{backgroundColor: '#3b5de7', width: "100%", color: "white", height: 50, borderColor: '#3b5de7',
                                    marginBottom: 10}}
                          onClick={onNextClick}>
                            {currentStep == 2? "Submit": "Next"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 text-center">
                    <p className="mb-0">
                      By registering you agree to the Bein Trust Bank{" "}
                      <Link to="#" className="text-primary">
                        Terms of Use
                      </Link>
                    </p>
                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>Already have an account ? <a href="/login" className="fw-medium text-primary">
                  Login</a> </p>
                <p>© {new Date().getFullYear()} Bein Trust Bank
                        </p>
              </div>
            </Col>
          </Row>
          </Container>
      </div>
    </React.Fragment>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
}

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account
  return { user, registrationError, loading }
}

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register)
