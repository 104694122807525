import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

import store from "./store"

// let persistor = persistStore(store);

const app = (
  <Provider store={store}>
      {/*<PersistGate persistor={persistor} >*/}
    <BrowserRouter>
      <App />
    </BrowserRouter>
      {/*</PersistGate>*/}
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
