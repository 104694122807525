import React, {useEffect, useState} from "react"
import { Row, Col } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//Import Pricing Cards
import CardPricing from "./card-pricing"

const LoanApply = () => {
  const [loans, setLoans] = useState([])

  useEffect(() => {
    fetch(`https://demobackend.mudiaga.com.ng/api/loans/all/1/1000`)
        .then(response => response.json())
        .then(response => {
          let res = response
          console.log({res})
          let myDocs = res?.data?.docs?.filter(el => el?.isCore === true)
          myDocs = myDocs?.filter(el => el?.status !== "deleted")
          console.log(myDocs)
          setLoans(myDocs)
        })
        .catch(error => {
          console.log({error})
        })
  },[])

  return (
    <React.Fragment>
      <div className="page-content">
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Bein Trust Bank" breadcrumbItem="Apply for a Loan" />

          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <h4>Choose a Loan Plan</h4>
                <p className="text-muted">
                  All our loans are flexible with low interest rates.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {loans.map((pricing, key) => (
              <CardPricing pricing={pricing} key={"_pricing_" + key} email={JSON.parse(localStorage.getItem("authUser"))?.email} />
            ))}
          </Row>
      </div>
    </React.Fragment>
  )
}
export default LoanApply
