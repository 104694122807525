import React, {useEffect, useRef, useState} from "react"
import { MDBDataTable } from "mdbreact"
import {Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
import {AvField, AvForm} from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";

const LoanAdmin = () => {
    const [allLoans, setAllLoans] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [data, setData] = useState({})
    const [email, setEmail] = useState("")
    const [loanName, setLoanName] = useState("")
    const [amount, setAmount] = useState("")
    const [profit, setProfit] = useState("")
    const [currency, setCurrency] = useState("$")
    const [totalInstallments, setTotalInstallments] = useState(false)
    const [createdSuccessfully, setCreatedSuccessfully] = useState(false)
    const [createFailed, setCreateFailed] = useState(false)
    const [editedSuccessfully, setEditedSuccessfully] = useState(false)
    const [editFailed, setEditFailed] = useState(false)
    const [deletedSuccessfully, setDeletedSuccessfully] = useState(false)
    const [deleteFailed, setDeleteFailed] = useState(false)

    const editFormRef = useRef();
    const createFormRef = useRef();

    const columns = [
        {
            label: "Loan Name",
            field: "loanName",
            sort: "asc",
            width: 150,
        },
        {
            label: "Amount",
            field: "amount",
            sort: "asc",
            width: 270,
        },
        {
            label: "Profit",
            field: "profit",
            sort: "asc",
            width: 200,
        },
        {
            label: "Total Installments",
            field: "totalInstallments",
            sort: "asc",
            width: 100,
        },
        {
            label: "Action",
            field: "action",
            width: 100,
        },
    ]

    const toggleEditModal = () => setShowEditModal(!showEditModal)
    const toggleCreateModal = () => setShowCreateModal(!showCreateModal)
    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)


    useEffect(() => {
        fetch(`https://demobackend.mudiaga.com.ng/api/loans/all/1/1000`)
            .then(response => response.json())
            .then(response => {
                let res = response
                res?.data?.docs?.forEach((item, index) => {
                    item.id = (
                        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.id}</div>
                    );
                    item.action = (
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#2f4395",
                                    width: "45%",
                                    textAlign: "center",
                                }}
                                onClick={() => selectUserToEdit(item?.loanName)}
                            >
                                Edit
                            </div>
                            <div
                                className="uil-trash-alt"
                                style={{
                                    cursor: "pointer",
                                    color: "white",
                                    fontSize: ".7em",
                                    padding: ".5rem",
                                    borderRadius: ".3rem",
                                    background: "#fb6262",
                                    width: "45%",
                                    textAlign: "center",
                                }}
                                onClick={() => selectUserToDelete(item?.loanName)}
                            >
                                Delete
                            </div>
                        </div>
                    );
                })
                console.log({res})
                let myDocs = res?.data?.docs?.filter(el => el?.isCore === true)
                myDocs = myDocs?.filter(el => el?.status !== "deleted")
                // se(myDocs)
                setAllLoans(response?.data?.docs)
                setData({
                    columns,
                    rows: myDocs
                })
            })
            .catch(error => {
                console.log({error})
            })
    },[])

    async function handleValidSubmit(event, values) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            email: "admin",
            loanName: values?.loanName,
            payload: values
        }
        try {
            const response = await fetch("https://demobackend.mudiaga.com.ng/api/loans/edit-loan", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setEditedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setEditFailed(true)
        }
    }


    async function handleValidCreate(event, values) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(values)
        let toPost = {
            email: "admin",
            isCore: true,
            ...values,
        }
        try {
            const response = await fetch("https://demobackend.mudiaga.com.ng/api/loans/new", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setCreatedSuccessfully(true)
            console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setCreateFailed(true)
        }
    }

    async function handleDeleteBeneficiary(aNum) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        console.log(aNum)
        let toPost = {
            email: "admin",
            loanName: aNum,
            payload: { status: "deleted" }
        }
        try {
            const response = await fetch("https://demobackend.mudiaga.com.ng/api/loans/edit-loan", {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(toPost),
            });

            const result = await response.json();
            setDeletedSuccessfully(true)
            // console.log("Result:", result);
        } catch (error) {
            console.error("Error:", error);
            setDeleteFailed(true)
        }
    }

    const selectUserToEdit = (name) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://demobackend.mudiaga.com.ng/api/loans/details/admin/${name}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setLoanName(res?.data[0]?.loanName)
                setAmount(res?.data[0]?.amount)
                setProfit(res?.data[0]?.profit)
                setTotalInstallments(res?.data[0]?.totalInstallments)
                toggleEditModal()
            })
            .catch(err => {
                console.log({err})
                toggleEditModal()
            })
        // setNickname()
    }

    const selectUserToDelete = (name) => {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        fetch(`https://demobackend.mudiaga.com.ng/api/loans/details/admin/${name}`)
            .then(res => res.json())
            .then(res => {
                // console.log("nickname: ", res?.data)
                setLoanName(res?.data[0]?.loanName)
                setAmount(res?.data[0]?.amount)
                setProfit(res?.data[0]?.profit)
                setTotalInstallments(res?.data[0]?.totalInstallments)
                toggleDeleteModal()
            })
            .catch(err => {
                console.log({err})
                toggleDeleteModal()
            })
        // setNickname()
    }

    return (
        <React.Fragment>
            <div className="page-content">

                <Breadcrumbs title="Bein Trust Bank" breadcrumbItem="Loan Admin" />

                <Row>
                    <Col>
                        <button className="btn btn-primary mb-3 float-end"
                        onClick={toggleCreateModal}>
                            Add Loan
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <MDBDataTable responsive bordered striped data={data} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showCreateModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Add Loan
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={createFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidCreate(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="loanName"
                                                    label="Loan Name"
                                                    value={loanName}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="amount"
                                                    label="Amount"
                                                    value={amount}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="profit"
                                                    label="Profit"
                                                    value={profit}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="totalInstallments"
                                                    label="Number of Installments"
                                                    value={totalInstallments}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleCreateModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => createFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showEditModal}
                            toggle={() => {
                                toggleEditModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Edit Loan
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <AvForm
                                    ref={editFormRef}
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit(e, v)
                                    }}
                                >
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="loanName"
                                                    label="Loan Name"
                                                    value={loanName}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="amount"
                                                    label="Amount"
                                                    value={amount}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="profit"
                                                    label="Profit"
                                                    value={profit}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 mb-3">
                                            <div className="form-group">
                                                <AvField
                                                    name="totalInstallments"
                                                    label="Number of Installments"
                                                    value={totalInstallments}
                                                    className="form-control"
                                                    placeholder=""
                                                    type="text"
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </Row>
                                </AvForm>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleEditModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => editFormRef?.current?.handleSubmit()}
                                >
                                    Save changes
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal
                            size="lg"
                            centered
                            isOpen={showDeleteModal}
                            toggle={() => {
                                toggleDeleteModal()
                            }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Delete Beneficiary
                                </h5>
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this beneficiary?</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    onClick={toggleDeleteModal}
                                    className="btn btn-primary waves-effect"
                                    data-dismiss="modal"
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => handleDeleteBeneficiary(loanName)}
                                >
                                    Yes, Delete
                                </button>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                {editedSuccessfully ? (
                    <SweetAlert
                        title="Edit successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your loan details have been updated!
                    </SweetAlert>
                ) : null}
                {editFailed ? (
                    <SweetAlert
                        title="Error in editing loan"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setEditFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {deletedSuccessfully ? (
                    <SweetAlert
                        title="Delete successful"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeletedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        Your loan has been deleted!
                    </SweetAlert>
                ) : null}
                {deleteFailed ? (
                    <SweetAlert
                        title="Error in deleting loan"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setDeleteFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
                {createdSuccessfully ? (
                    <SweetAlert
                        title="Loan Successfully added"
                        success
                        // showCancel
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreatedSuccessfully(false)
                            window.location.reload();
                        }}
                        // onCancel={() => {
                        //     setSuccessMsg(false)
                        // }}
                    >
                        A new loan plan has been added!
                    </SweetAlert>
                ) : null}
                {createFailed ? (
                    <SweetAlert
                        title="Error in adding loan"
                        warning
                        // showCancel
                        confirmButtonText="Ok"
                        confirmBtnBsStyle="success"
                        // cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            setCreateFailed(false)
                            // setsuccess_dlg(true)
                            // setdynamic_title("Deleted")
                            // setdynamic_description("Your file has been deleted.")
                        }}
                        // onCancel={() => setconfirm_alert(false)}
                    >
                        Please try again later!
                    </SweetAlert>
                ) : null}
            </div>

        </React.Fragment>
    )
}

export default LoanAdmin
