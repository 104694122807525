import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import avatar2 from "../../assets/images/users/avatar-2.jpg"

const Sidebar = props => {
  const [fullName, setFullName] = useState("")
  const [user, setUser] = useState("")

  useEffect(() => {
    let res = localStorage.getItem("authUser")
    console.log({res})
    setUser(JSON.parse(res))
  },[])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            {/*<div className="user-img">*/}
            {/*  <img src={avatar2} alt="" className="avatar-md mx-auto rounded-circle" />*/}
            {/*</div>*/}

            <div className="mx-auto" style={{
              height: 70,
              width: 70,
              backgroundColor: "#283d92",
              borderRadius: 70,
              paddingTop: 10,
            }}>
              <h1 className="text-white font-weight-semibold my-auto">{user?.firstName?.substring(0,1)}</h1>
            </div>

              <div className="mt-3">
                <Link to="#" className="text-dark fw-medium font-size-16">{`${user?.firstName} ${user?.lastName}`}</Link>
                {/*<p className="text-body mt-1 mb-0 font-size-13">UI/UX Designer</p>*/}
              </div>
            </div>
            <div data-simplebar className="h-100">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
          </div>
        </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {}
)(withRouter(withTranslation()(Sidebar)))
