import React, {useEffect, useState} from "react"
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Row} from "reactstrap"
import DomesticTransfer from "../../assets/images/icons/domestic transfer-01.svg"
import InterbankTransfer from "../../assets/images/icons/Interbank Transfer-01.svg"
import WireTransfer from "../../assets/images/icons/Wire transfer.svg"
import ActiveLoans from "../../assets/images/icons/active loan-01.svg"
import RunningFDR from "../../assets/images/icons/FDR-01.svg"
import Beneficiaries from "../../assets/images/icons/Beneficiary-01.svg"
import Logo from "../../assets/images/logo-light.png"

const Dashboard = () => {

  const [accountNumber, setAccountNumber] = useState("")
  const [balance, setBalance] = useState("")
  const [accountType, setAccountType] = useState("")
  const [status, setStatus] = useState("")
  const [currency, setCurrency] = useState("")

  const fetchAccountNumber = async () => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const email = obj?.email;

    let acct = await fetch(`https://demobackend.mudiaga.com.ng/api/accounts/user/${email}`)
    acct = await acct.json();
    acct = acct.data[0];

    // console.log(acct)

    return acct;
  }

  useEffect(() => {
    fetchAccountNumber()
        .then(res => {
          // console.log({res})
          setBalance(res?.balance)
          setAccountType(res?.accountType)
          setAccountNumber(res?.accountNumber)
          setCurrency(res?.currency)
        })

  },[])

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12 mb-4">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18"></h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to Bein Trust Bank</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>

      </div>
      <div className="container">
        <Row>
          <Col>
            <br/><br/>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card style={{backgroundColor: "#283d92"}} className="text-white mt-md-2 mt-sm-4">
              <CardBody>
                <div className="row">
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Account Number
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{accountNumber}</h2>
                  </div>
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Available Balance
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{currency}{balance}</h2>
                  </div>
                  <div className="col-md-4 sm-12 mb-3">
                    <h5 className="mt-0 mb-4 text-white">
                      <i className="mdi mdi-bullseye-arrow me-3"></i> Account Type
                    </h5>
                    <h2 className="text-white" style={{marginLeft: 30, fontSize: "1.5rem"}}>{accountType?.toUpperCase()} (Active)</h2>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card outline color="primary" className="border" style={{cursor: 'pointer'}}
            onClick={() => window.location.href="/my-transactions"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Transaction History
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={DomesticTransfer} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="primary" className="border" style={{cursor: 'pointer'}}
            onClick={() => window.location.href="/interbank-transfer"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Inter Bank Transfers
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={InterbankTransfer} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="primary" className="border" style={{cursor: 'pointer'}}
            onClick={() => window.location.href="/wire-transfer"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Wire Transfers
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={WireTransfer} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <Card outline color="primary" className="border" style={{cursor: 'pointer'}}
                  onClick={() => window.location.href="/apply-loan"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Loans
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={ActiveLoans} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card outline color="primary" className="border" style={{cursor: 'pointer'}}
                  onClick={() => window.location.href="/apply-fdr"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Federal Reserves
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={RunningFDR} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} className="mb-4">
            <Card outline color="primary" className="border mb-4" style={{cursor: 'pointer'}}
                  onClick={() => window.location.href="/beneficiaries"}>
              <CardHeader className="bg-transparent text-center">
                <h4 className="my-0 text-primary text-center">
                  <i className="uil uil-check-circle me-3"></i>Beneficiaries
                </h4>
              </CardHeader>
              <CardBody className="text-center">
                <img src={Beneficiaries} className="dashboard-icons"
                     style={{width: 200, height: 100, alignSelf: 'center', cursor: 'pointer'}}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
